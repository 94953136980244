<template>
  <div class="group-new" v-if="!isProcessing">
    <p class="group-new__text">以下のユーザーをFBグループに追加してください。</p>
    <div class="group-new__user" v-for="user in notFacebookUsers" :key="user.uid">
      <div class="group-new__user__tag" v-if="exemption(user.uid)">
        <p class="group-new__user__tag__text">{{ tagName(exemption(user.uid).taid) }}</p>
      </div>
      <group-user class="group-new__user__detail" :user="user" :email="emails[user.uid]" />
      <div class="group-new__user__action">
        <v-btn class="group-new__user__action__btn" @click="open(user)" depressed>追加した</v-btn>
      </div>
    </div>
    <confirm-dialog ref="confirm" title="FBグループに追加" :msg="dialogMsg"
                    @do-action="addFacebookGroup()" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/common/confirm'
import GroupUser from '@/components/common/user/detail'

export default {
  name: 'group_new',
  components: { ConfirmDialog, GroupUser },
  data () {
    return {
      // 追加するユーザーID
      uid: null,
      // 追加するユーザー名
      name: ''
    }
  },
  async mounted () {
    const promises = []
    // 全ユーザー情報の取得
    if (!this.hasGot) promises.push(this.$store.dispatch('users/getUsers'))
    // タグ情報の取得
    if (this.tags.length === 0) promises.push(this.$store.dispatch('tags/getTags'))
    // 例外情報の取得
    if (this.exemptions.length === 0 ) promises.push(this.$store.dispatch('exemptions/getExemptions'))
    // ユーザーのメールアドレス取得
    if (Object.keys(this.emails).length === 0) promises.push(this.$store.dispatch('functions/getAllEmails'))

    await Promise.all(promises)

    // 通常ユーザーの場合、登録済みかどうかを確認するためカード情報を取得する
    const cardPromises = []
    Object.keys(this.users).forEach(uid => {
      const userExemption = this.exemptions.find(exemption => exemption.uid === uid)
      if (!userExemption && !this.cards[uid] && !this.users[uid].isFacebookMember && !this.users[uid].isDeleted) cardPromises.push(this.$store.dispatch('cards/getCard', uid))
    })

    await Promise.all(cardPromises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 全ユーザー情報を取得済みかどうか
     */
    hasGot () {
      return this.$store.getters['users/hasGot']
    },
    /**
     * @return {Object[]} タグ情報一覧
     */
    tags () {
      return this.$store.getters['tags/tags']
    },
    /**
     * @return {Object} 全ユーザー情報
     */
    users () {
      return this.$store.getters['users/users']
    },
    /**
     * @return {Object[]} Facebookグループ未加入かつ退会していないユーザー
     */
    notFacebookUsers () {
      const notFacebookUsers = {}
      Object.keys(this.users).forEach(uid => {
        const user = this.users[uid]
        const userExemption = this.exemptions.find(exemption => exemption.uid === uid)
        // カードが登録済みまたは例外ユーザーで名前が存在すれば登録済み
        const isResigtrated = this.cards[uid] || (userExemption && this.users[uid].name)
        if (isResigtrated && !user.isFacebookMember && !user.isDeleted) notFacebookUsers[uid] = user
      })
      return notFacebookUsers
    },
    /**
     * @return {Object[]} 例外情報一覧
     */
    exemptions () {
      return this.$store.getters['exemptions/exemptions']
    },
    /**
     * @return {Object} カード情報一覧
     */
    cards () {
      return this.$store.getters['cards/cards']
    },
    /**
     * @return {Object} ユーザーのメールアドレス一覧
     */
    emails () {
      return this.$store.getters.emails
    },
    /**
     * @return {String} 確認ダイアログに表示する文
     */
    dialogMsg () {
      return this.name + 'さんをFBグループに追加した処理にします。一度処理を行うと元に戻すことができません。'
    },
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * 例外情報を取得する
     * @param {String} uid ユーザーのID
     * @return {Object} 例外情報
     */
    exemption (uid) {
      return this.exemptions.find(exemption => exemption.uid === uid)
    },
    /**
     * タグ名を取得する
     * @param {String} taid タグのID
     * @return {String} タグ名
     */
    tagName (taid) {
      return this.$store.getters['tags/tag'](taid).name
    },
    /**
     * ダイアログを開いて、加入させるユーザーIDを設定する
     * @param {Object} user 加入させるユーザーのID
     */
    open (user) {
      this.$refs.confirm.open()
      this.uid = user.uid
      this.name = user.name
    },
    /**
     * Facebookグループ加入処理
     */
    async addFacebookGroup () {
      this.$store.commit('setSubmitting', true)

      // ユーザー画面側で退会されていないかチェックするため最新状態を取得する
      const user = await this.$store.dispatch('users/getUser', this.uid)

      // 退会済みであればテロップを出して処理を中断
      if (user.isDeleted) {
        this.$store.commit('setTelop', { show: true, msg: 'このユーザーは既に退会済みです。', type: 'error' })
        this.$store.commit('setSubmitting', false)
        return
      }

      const params = {
        isFacebookMember: true,
        updatedAt: new Date()
      }

      await this.$store.dispatch('users/updateUser', { uid: this.uid, params: params })

      // テロップを表示して移動
      this.$store.commit('setTelop', { show: true, msg: 'Facebookグループに追加しました', type: 'success' })
      this.$router.push({ name: 'home' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.group-new {
  width: 100vw;
  max-width: $max_width;
  padding: $padding_height $padding_width;
  margin: $header_height auto 0 auto;
  &__text {
    font-size: 1.2rem;
  }
  &__user {
    position: relative;
    margin-top: 20px;
    &:first-child {
      margin-top: 0;
    }
    &__action {
      position: absolute;
      top: 0;
      right: $padding_width;
      min-width: 60px;
      margin-left: 10px;
      &__btn {
        margin: 0 -10px 0 auto;
        font-size: 1rem;
        color: $white_color;
        border-radius: 15px;
        &.v-btn:not(.v-btn--round).v-size--default {
          min-width: auto;
          height: auto;
          padding: 8px 16px;
        }
        &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
          background-color: $orange_color;
        }
      }
    }
    &__tag {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      min-width: 60px;
      max-width: calc((100% - 10px * 4 - 100px) / 2);
      height: 34px;
      padding: 0 15px;
      margin-left: 10px;
      border: 2px solid $orange_color;
      border-radius: 5px;
      &__text {
        margin: 0;
        overflow: hidden;
        font-size: 1.2rem;
        color: $orange_color;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: $white_color;
      }
    }
  }
  &__warning {
    margin-top: 30px;
    font-size: 1.2rem;
    color: $red_color;
    text-align: center;
  }
}
</style>
